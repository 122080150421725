import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import CloseControl from './close';

export default class View extends Component {
  render() {
    let edge = this.props.widget.detailsData;

    return (
      <Card style={this.props.cardStyle}>
        <Card.Header>
          Edge Details
          <span className="float-right">
            <CloseControl widget={this.props.widget} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <table className="properties">
            <tbody>
              <tr>
                <td className="prop-name-column">ID</td>
                <td className="prop-value-column">{edge.id}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Class</td>
                <td className="prop-value-column">{edge.class}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
    );
  }
}
