import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { GoX } from 'react-icons/go';

export default class CloseControl extends Component {
  handleClose = () => {
    this.props.widget.widgetInterface.updateDetails(false, "summary", null);
  }

  render() {
    return (
      <ButtonGroup size="sm" className="header-options">
        <Button variant="light" onClick={this.handleClose}><GoX/></Button>
      </ButtonGroup>
    );
  }
}
