import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Api from "context/api";

// Supported widgets
import GraphViewV1Widget from "widgets/v1/graphView/index";
// Demos
import GraphViewV1SimpleDemo from "demos/graphViewV1Basic/index";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      auth: null,
      api: null
    };

    this.state.api = new Api(
      this.state,
      (state) => { this.setState(state) },
      () => { return this.state });
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/v1/graph-view/:itemId"
            render={(props) => <GraphViewV1Widget {...props} app={this.state} />}
          />

          <Route exact path="/demos/v1/graph-view/simple"
            render={(props) => <GraphViewV1SimpleDemo {...props} app={this.state} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
