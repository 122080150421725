/**
 * Class to store static application configuration
 */
export default class Config {

  // Unlocks debugging features and verbose logging.
  static debug = true;

  static apiBaseUrl() {
    return "https://api.blobhub.io";
  }

  static webBaseUrl() {
    return "https://blobhub.io";
  }
}
