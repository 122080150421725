import React, { Component } from 'react';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import { GoInfo, GoGitCompare } from 'react-icons/go';
import CloseControl from './close';

export default class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: "info"
    };
  }

  handleNavigationView = () => {
    this.setState({ view: "navigation" });

  }

  handleInfoView = () => {
    this.setState({ view: "info" });
  }

  render() {
    let vertex = this.props.widget.detailsData;
    let vertexPropertyNames = Object.keys(vertex.properties);

    return (
      <Card style={this.props.cardStyle}>
        <Card.Header>
          Vertex Details
          <span className="float-right">
              <ButtonGroup size="sm" className="header-options">
                <Button variant="light" onClick={this.handleInfoView}><GoInfo/></Button>
                <Button variant="light" onClick={this.handleNavigationView}><GoGitCompare/></Button>
              </ButtonGroup>
              &nbsp;
              &nbsp;
            <CloseControl widget={this.props.widget} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <table className="properties">
            <tbody>
              <tr>
                <td className="prop-name-column">ID</td>
                <td className="prop-value-column">{vertex.id}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Class</td>
                <td className="prop-value-column">{vertex.class}</td>
              </tr>
            </tbody>
          </table>
          {"info" === this.state.view && vertexPropertyNames.length > 0 &&
            <>
              <Card.Text as="h6">Properties</Card.Text>
              <table className="properties">
                <tbody>
                  {vertexPropertyNames.map((propertyName, index) => {
                    return (
                      <tr key={index}>
                        <td className="prop-name-column">{propertyName}</td>
                        <td className="prop-value-column">{vertex.properties[propertyName].toString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
          {"navigation" === this.state.view &&
            <>
              {vertex.edges.incoming.length > 0 &&
                <>
                  <Card.Text as="h6">Incoming</Card.Text>
                  <table className="properties">
                    <tbody>
                      {vertex.edges.incoming.map((edges, index) => {
                        return (
                          <tr key={index}>
                            <td className="prop-name-column">{edges.class}</td>
                            <td className="prop-value-column">{edges.count}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              }
              {vertex.edges.outgoing.length > 0 &&
                <>
                  <Card.Text as="h6">Outgoing</Card.Text>
                  <table className="properties">
                    <tbody>
                      {vertex.edges.outgoing.map((edges, index) => {
                        return (
                          <tr key={index}>
                            <td className="prop-name-column">{edges.class}</td>
                            <td className="prop-value-column">{edges.count}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              }
            </>
          }
        </Card.Body>
      </Card>
    );
  }
}
