import React, { Component } from 'react';
import vis from 'vis-network/dist/vis-network.min.js';
import 'vis-network/dist/vis-network.min.css';
import merge from "lodash/merge";
import './graphCtrlVis.css';;

export default class GraphControl extends Component {
  constructor(props) {
    super(props);

    this.uiVertexIndex = new Map();
    this.network = null;
    this.nodes = new vis.DataSet([]);
    this.edges = new vis.DataSet([]);

    this.props.widget.graphCtrlInterface = {
      incorporateDiff: this.incorporateDiff,
      activate: this.activate,
      clear: this.clear
    };

    this.viewParsed = false;
    this.networkOptions = {
      physics: {
        enabled: true,
        stabilization: {
          enabled: true,
          fit: true
        }
      },
      layout: {
        randomSeed: 2
      },
      nodes: {
        shape: "box",
        shapeProperties: {
          borderRadius: 2
        },
        font: {
          align: "left",
          multi: true,
          face: "Roboto",
          size: 12
        },
        margin: {
          top: 5,
          bottom: 5,
          left: 8,
          right: 8
        },
        color: {
          background: "#ffffff",
          border: "#bbbbbb",
          highlight: {
            background: "#dddddd",
            border: "#aaaaaa",
          }
        },
        shadow: {
          enabled: true,
          color: '#eeeeee',
          x: 2,
          y: 2,
          size: 1
        }
      },
      edges: {
        width: 1,
        font: {
          size: 12,
          face: "Roboto"
        },
        shadow: {
          enabled: false
        },
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 0.5
          }
        }
      },
      groups: {
        useDefaultGroups: true
      }
    };
    this.vertexStyleIndex = {};
    this.edgeStyleIndex = {};
    this.vertexLabelTemplateIndex = {};
    this.edgeLabelTemplateIndex = {};
  }

  /**
   * Control interface
   */

  activate = () => {
    this.adjustUiToRecentUpdates();
  }

  incorporateDiff = (waveIndex) => {
    this.incorporateDiffCore(waveIndex);
    this.adjustUiToRecentUpdates();
  }

  clear = () => {
    this.edges.clear();
    this.nodes.clear();
    this.adjustUiToRecentUpdates();
  }

  /**
   * Callbacks
   */

  onSelectContent = (params) => {
    for (var nodeId of params.nodes) {
      this.applyNodeSelection(nodeId);
      return;
    }

    for (var edgeId of params.edges) {
      this.applyEdgeSelection(edgeId);
      return;
    }

    this.props.widget.widgetInterface.updateDetails(true, "summary", null);
  }

  applyNodeSelection(nodeId) {
    let uiVertex = this.nodes.get(nodeId);
    let vertex = uiVertex.vertex;
    this.props.widget.widgetInterface.updateDetails(true, "vertex", vertex);
  }

  applyEdgeSelection(edgeId) {
    let uiEdge = this.edges.get(edgeId);
    let edge = uiEdge.edge;
    this.props.widget.widgetInterface.updateDetails(true, "edge", edge);
  }

  /**
   * Rendering
   */

  createGraph() {
    // create a network
    var container = document.getElementById("explore-preview-graph");
    var data = {
      nodes: this.nodes,
      edges: this.edges
    };
    this.network = new vis.Network(container, data, this.networkOptions);

    // Setup callbacks
    this.network.on("select", this.onSelectContent);
  }

  incorporateDiffCore(waveIndex) {
    if ( !this.props.graph.mostRecentDiff ) {
      return;
    }

    // Process new nodes
    for (var nodeId of this.props.graph.mostRecentDiff.nodes()) {
      // Skip nodes that are already present
      if ( this.nodes.get(nodeId) ) {
        // TODO: Update style according to the wave
        continue;
      }

      // Add node
      const node = this.props.graph.mostRecentDiff.node(nodeId);
      const LabelTemplate = this.findLabelTemplate(waveIndex, "vertex", node);
      const nodeLabel = this.renderTemplate(LabelTemplate, {vertex: node});
      const group = this.formatVertexGroupName(waveIndex, node);
      const vertexStyle = this.formatVertexStyle(waveIndex, node);
      const uiVertex = merge(vertexStyle, {
        id: nodeId,
        label: nodeLabel,
        vertex: node,
        group: group
      });
      this.nodes.add(uiVertex);
      this.uiVertexIndex.set(nodeId, uiVertex);

      // Register group if it does not exist
      if ( !this.networkOptions.groups[group] ) {
        this.networkOptions.groups[group] = merge({}, this.networkOptions.nodes);
      }
    }

    // Process new edges
    for (var edgeObj of this.props.graph.mostRecentDiff.edges()) {
      // Skip edges that are already present
      const edge = this.props.graph.mostRecentDiff.edge(edgeObj);
      if ( this.edges.get(edge.id) ) {
        // TODO: Update style according to the wave
        continue;
      }

      // Add edge
      const edgeTemplate = this.findLabelTemplate(waveIndex, "edge", edge)
      const edgeLabel = this.renderTemplate(edgeTemplate, {edge: edge});
      const edgeStyle = this.formatEdgeStyle(waveIndex, edge);
      const uiEdge = merge(edgeStyle, {
        id: edge.id,
        from: edgeObj.v,
        to: edgeObj.w,
        label: edgeLabel,
        edge: edge
      });
      this.edges.add(uiEdge);
    }

    // Update options
    this.network.setOptions(this.networkOptions);
  }

  adjustUiToRecentUpdates() {
    this.forceUpdate();
    this.network.fit();
  }

  /**
   * Appearance
   */

  formatVertexGroupName(waveIndex, vertex) {
    const className = vertex.class || "unknown";
    return `${waveIndex}-${className}`;
  }

  formatVertexStyle(waveIndex, vertex) {
    const wave = this.vertexStyleIndex[`wave-${waveIndex}`] || {};
    const combined = merge({}, wave);
    return combined;
  }

  formatEdgeStyle(waveIndex, edge) {
    const className = edge.class || "unknown";
    const globalClass = this.edgeStyleIndex[`global-${className}`] || {};
    const wave = this.edgeStyleIndex[`wave-${waveIndex}`] || {};
    const waveClass = this.edgeStyleIndex[`${waveIndex}-${className}`] || {};
    const combined = merge(globalClass, wave, waveClass);
    return combined;
  }

  findLabelTemplate(waveIndex, type, object) {
    let templateIndex = null;
    if ( "vertex" === type ) {
      templateIndex = this.vertexLabelTemplateIndex;
    } else if ( "edge" === type ) {
      templateIndex = this.edgeLabelTemplateIndex;
    } else {
      return "unknown";
    }

    const className = object.class || "unknown";

    // wave-class
    let indexKey = `wave-class-${waveIndex}-${className}`;
    let template = templateIndex[indexKey];
    if ( template ) { return template; }

    // wave
    indexKey = `wave-${waveIndex}`;
    template = templateIndex[indexKey];
    if ( template ) { return template; }

    // global-class
    indexKey = `global-class-${className}`;
    template = templateIndex[indexKey];
    if ( template ) { return template; }

    // global
    indexKey = `global`;
    template = templateIndex[indexKey];
    if ( template ) { return template; }

    // Should never get here. "global" templates should be available for all objects.
    if ( "vertex" === type ) {
      return "${vertex.class} (${vertex.id})";
    } else if ( "edge" === type ) {
      return "${edge.class}";
    } else {
      return "unknown";
    }
  }

  renderTemplate(template, data) {
    let inject = (str, obj) => str.replace(/\${(.*?)}/g, (x, g) => {
      try {
        let value = obj;
        for ( let segment of g.split(".") ) {
          value = value[segment];
        }
        if ( undefined === value ) {
          throw new Error("undefined");
        }
        return value;
      } catch (err) {
        return "<?>";
      }
    });
    return inject(template, data);
  }

  /**
   * View parsing
   */

  parseViewData() {
    if ( this.viewParsed || !this.props.view ) {
      return;
    }

    // Determine number of queries
    let totalQueries = 0;
    if ( this.props.view.queries ) {
      totalQueries = this.props.view.queries.length;
    }

    // Init options
    if ( !this.networkOptions.groups ) {
      this.networkOptions.groups = {};
    }

    // Merge global graph options
    if ( this.props.view.appearance && this.props.view.appearance.global ) {
      this.networkOptions = merge(this.networkOptions, this.props.view.appearance.global);
    }
    // Merge global vertex options
    if ( this.props.view.appearance && this.props.view.appearance.vertices ) {
      if ( this.props.view.appearance.vertices.all && this.props.view.appearance.vertices.all.style ) {
        this.networkOptions.nodes = merge(this.networkOptions.nodes, this.props.view.appearance.vertices.all.style);
      }
      if ( this.props.view.appearance.vertices.classes ) {
        for ( let classAppearance of this.props.view.appearance.vertices.classes ) {
          if ( !classAppearance.class || !classAppearance.style ) {
            continue;
          }
          for ( let waveIndex = 0 ; waveIndex < totalQueries ; ++waveIndex ) {
            const groupName = this.formatVertexGroupName(waveIndex, classAppearance);
            const groupStyle = classAppearance.style;
            const existingGroupStyle = this.networkOptions.groups[groupName];
            if ( existingGroupStyle ) {
              this.networkOptions.groups[groupName] = merge(existingGroupStyle, groupStyle);
            } else {
              this.networkOptions.groups[groupName] = merge({}, groupStyle);
            }
          }
        }
      }
    }
    // Merge global edge options
    if ( this.props.view.appearance && this.props.view.appearance.edges ) {
      if ( this.props.view.appearance.edges.all && this.props.view.appearance.edges.all.style ) {
        this.networkOptions.edges = merge(this.networkOptions.edges, this.props.view.appearance.edges.all.style);
      }
      if ( this.props.view.appearance.edges.classes ) {
        for ( let classAppearance of this.props.view.appearance.edges.classes ) {
          if ( !classAppearance.class || !classAppearance.style ) {
            continue;
          }
          const indexKey = `global-${classAppearance.class}`;
          this.edgeStyleIndex[indexKey] = merge({}, classAppearance.style);
        }
      }
    }

    // Merge query specific vertices options
    if ( this.props.view.queries ) {
      for ( let waveIndex = 0 ; waveIndex < this.props.view.queries.length ; ++waveIndex ) {
        const query = this.props.view.queries[waveIndex];
        if ( query.appearance && query.appearance.vertices ) {
          // All
          if ( query.appearance.vertices.all && query.appearance.vertices.all.style ) {
            const indexKey = `wave-${waveIndex}`;
            this.vertexStyleIndex[indexKey] = merge({}, query.appearance.vertices.all.style);
          }
          // Per-class
          if ( query.appearance.vertices.classes ) {
            for ( let classAppearance of query.appearance.vertices.classes ) {
              if ( !classAppearance.class || !classAppearance.style ) {
                continue;
              }
              const groupName = this.formatVertexGroupName(waveIndex, classAppearance);
              const groupStyle = classAppearance.style;
              const existingGroupStyle = this.networkOptions.groups[groupName];
              if ( existingGroupStyle ) {
                this.networkOptions.groups[groupName] = merge(existingGroupStyle, groupStyle);
              } else {
                this.networkOptions.groups[groupName] = merge({}, groupStyle);
              }
            }
          }
        }
      }
    }
    // Merge query specific edge options
    if ( this.props.view.queries ) {
      for ( let waveIndex = 0 ; waveIndex < this.props.view.queries.length ; ++waveIndex ) {
        const query = this.props.view.queries[waveIndex];
        if ( query.appearance && query.appearance.edges ) {
          // All
          if ( query.appearance.edges.all && query.appearance.edges.all.style ) {
            const indexKey = `wave-${waveIndex}`;
            this.edgeStyleIndex[indexKey] = merge({}, query.appearance.edges.all.style);
          }
          // Per-class
          if ( query.appearance.edges.classes ) {
            for ( let classAppearance of query.appearance.edges.classes ) {
              if ( !classAppearance.class || !classAppearance.style ) {
                continue;
              }
              const indexKey = `${waveIndex}-${classAppearance.class}`;
              this.edgeStyleIndex[indexKey] = merge({}, classAppearance.style);
            }
          }
        }
      }
    }

    // Populate template indexes with global templates
    if ( this.props.view.appearance ) {
      // Vertices
      if ( this.props.view.appearance.vertices ) {
        // All
        if ( this.props.view.appearance.vertices.all && this.props.view.appearance.vertices.all.label ) {
          const indexKey = `global`;
          /*eslint no-template-curly-in-string: "off"*/
          this.vertexLabelTemplateIndex[indexKey] =
            this.props.view.appearance.vertices.all.label.template || "${vertex.class} (${vertex.id})";
        }
        // Per-class
        if ( this.props.view.appearance.vertices.classes ) {
          for ( let classAppearance of this.props.view.appearance.vertices.classes ) {
            if ( !classAppearance.class || !classAppearance.label || !classAppearance.label.template ) {
              continue;
            }
            const indexKey = `global-class-${classAppearance.class}`;
            this.vertexLabelTemplateIndex[indexKey] = classAppearance.label.template;
          }
        }
      }
      // Edges
      if ( this.props.view.appearance.edges ) {
        // All
        if ( this.props.view.appearance.edges.all && this.props.view.appearance.edges.all.label ) {
          const indexKey = `global`;
          /*eslint no-template-curly-in-string: "off"*/
          this.edgeLabelTemplateIndex[indexKey] =
            this.props.view.appearance.edges.all.label.template || "${edge.class}";
        }
        // Per-class
        if ( this.props.view.appearance.edges.classes ) {
          for ( let classAppearance of this.props.view.appearance.edges.classes ) {
            if ( !classAppearance.class || !classAppearance.label || !classAppearance.label.template ) {
              continue;
            }
            const indexKey = `global-class-${classAppearance.class}`;
            this.edgeLabelTemplateIndex[indexKey] = classAppearance.label.template;
          }
        }
      }
    }
    // Populate template indexes with query templates
    if ( this.props.view.queries ) {
      for ( let waveIndex = 0 ; waveIndex < this.props.view.queries.length ; ++waveIndex ) {
        const query = this.props.view.queries[waveIndex];
        // Vertices
        if ( query.appearance && query.appearance.vertices ) {
          // All
          if ( query.appearance.vertices.all && query.appearance.vertices.all.label &&
            query.appearance.vertices.all.label.template ) {
            const indexKey = `wave-${waveIndex}`;
            this.vertexLabelTemplateIndex[indexKey] = query.appearance.vertices.all.label.template;
          }
          // Per-class
          if ( query.appearance.vertices.classes ) {
            for ( let classAppearance of query.appearance.vertices.classes ) {
              if ( !classAppearance.class || !classAppearance.label || !classAppearance.label.template ) {
                continue;
              }
              const indexKey = `wave-class-${waveIndex}-${classAppearance.class}`;
              this.vertexLabelTemplateIndex[indexKey] = classAppearance.label.template;
            }
          }
        }
        // Edges
        if ( query.appearance && query.appearance.edges ) {
          // All
          if ( query.appearance.edges.all && query.appearance.edges.all.label &&
            query.appearance.edges.all.label.template ) {
            const indexKey = `wave-${waveIndex}`;
            this.edgeLabelTemplateIndex[indexKey] = query.appearance.edges.all.label.template;
          }
          // Per-class
          if ( query.appearance.edges.classes ) {
            for ( let classAppearance of query.appearance.edges.classes ) {
              if ( !classAppearance.class || !classAppearance.label || !classAppearance.label.template ) {
                continue;
              }
              const indexKey = `wave-class-${waveIndex}-${classAppearance.class}`;
              this.edgeLabelTemplateIndex[indexKey] = classAppearance.label.template;
            }
          }
        }
      }
    }

    // Apply changes
    if ( this.network ) {
      this.network.setOptions(this.networkOptions);
    }

    // Debugging helpers
    // console.log("vertexLabelTemplateIndex");
    // console.log(this.vertexLabelTemplateIndex);
    // console.log("edgeLabelTemplateIndex");
    // console.log(this.edgeLabelTemplateIndex);

    this.viewParsed = true;
  }

  /**
   * Component
   */

  componentDidMount() {
    this.parseViewData();
    this.createGraph();
  }

  componentDidUpdate(prevProps) {
    this.parseViewData();
  }

  render() {
    return (
      <>
        <div id="explore-preview-graph" className="explore-preview-graph" />
      </>
    );
  }
}
