import axios from 'axios';
import Config from 'context/config';

export default class Api {

  // TODO: Rename all methods to match naming convention.

  constructor(state, setAppState, getAppState) {
    this.setAppState = setAppState;
    this.getAppState = getAppState;

    // Currently supported API version
    this.api_version = "v1";

    // Default client for basic operations
    this.axios = axios.create({
      baseURL: Config.apiBaseUrl()
    });

    // Default client for basic authenticated operations
    this.isAuthenticating = false;
    this.authSubscribers = [];
    this.axiosAuth = axios.create({
      baseURL: Config.apiBaseUrl()
    });
    this.axiosAuth.interceptors.request.use((config) => { return this.auth_header_interceptor(config) }, null);

    // Client capable of refreshing token in response to auth errors
    this.isRefreshing = false;
    this.refreshSubscribers = [];
    this.axiosRefresh = axios.create({
      baseURL: Config.apiBaseUrl()
    });
    this.axiosRefresh.interceptors.request.use((config) => { return this.auth_header_interceptor(config) }, null);
    this.axiosRefresh.interceptors.response.use(null, (error) => { return this.auth_error_interceptor(error) });

    // Users cache
    this.usersCacheById = {};

    // Load locally cached authentication state
    this.loadLocalAuthState(state);
  }

  log(...args) {
    if ( Config.debug ) {
      console.log(...args);
    }
  }

  /**
   * Public state controls
   */

  loadLocalAuthState(state) {
    try {
      // Attempt to load creds from local storage
      const localAuth = localStorage.getItem("auth");
      const auth = JSON.parse(localAuth);
      if ( auth ) {
        state.authenticated = auth != null;
        state.auth = auth;
      } else {
        throw Error("no_creds_found");
      }
    } catch (err) {
      // Authentication is delayed until API interaction is requested
    }
  }

  signout() {
    // Clear authentication data
    this.unAuthenticate();

    // Retrieve anonymous creds for API access
    this.authCognitoAnonymousGet();
  }

  /**
   * State management
   */

  authenticate(anonymous, refreshToken, accessToken, expiresAt) {
    const auth = {
      anonymous: anonymous,
      refreshToken: refreshToken,
      accessToken: accessToken,
      expiresAt: expiresAt
    };
    this.setAppState({
      authenticated: true,
      auth: auth
    });
    localStorage.setItem("auth", JSON.stringify(auth));
  }

  refreshToken(accessToken, expiresAt) {
    const auth = {
      anonymous: this.getAppState().auth.anonymous,
      refreshToken: this.getAppState().auth.refreshToken,
      accessToken: accessToken,
      expiresAt: expiresAt
    };
    this.setAppState({
      authenticated: true,
      auth: auth
    });
    localStorage.setItem("auth", JSON.stringify(auth));
  }

  unAuthenticate() {
    this.setAppState({
      authenticated: false,
      auth: null
    });
    localStorage.setItem("auth", JSON.stringify(null));
  }

  /**
   * auth
   */

  appendAuthorizationToken(config) {
    var state = this.getAppState();
    config.headers["Authorization"] = `Bearer ${state.auth.accessToken}`;
  }

  auth_header_interceptor(config) {
    var state = this.getAppState();
    if ( state.authenticated ) {
      this.appendAuthorizationToken(config);
      return config;
    } else {
      this.log("[auth_header_interceptor] Initiating anonymous authentication...");

      this.authCognitoAnonymousGet();

      const authSubscribers = new Promise(resolve => {
        this.authSubscribers.push(() => {
          this.log("[auth_header_interceptor] Resolving...");
          this.appendAuthorizationToken(config);
          resolve(config);
        });
      });
      return authSubscribers;
    }
  }

  auth_error_interceptor(error) {
    this.log("[auth_error_interceptor] Entry point: " + error);

    if ( !error.response ) {
      return Promise.reject(error);
    }

    const { config, response: { status } } = error;
    const originalRequest = config;

    if ( 401 === status ) {
      this.log("[auth_error_interceptor] Initiating refresh...");

      if ( !this.isRefreshing ) {
        this.log("[auth_error_interceptor] Initial refresh request observed");

        this.isRefreshing = true;
        this.auth_cognito_refresh_post().then(response => {
          this.log("[auth_error_interceptor] Notifying refresh subscribers...");

          this.isRefreshing = false;
          this.refreshSubscribers.map(callback => callback());
          this.refreshSubscribers = [];
        }, error => {
          this.log("[auth_error_interceptor] Cleaning up subscribers upon failure to refresh...");

          this.isRefreshing = false;
          this.refreshSubscribers = [];
        });
      }
      const requestSubscribers = new Promise(resolve => {
        this.refreshSubscribers.push(() => {
          resolve(this.axiosAuth(originalRequest));
        });
      });
      return requestSubscribers;
    }
    return Promise.reject(error);
  }

  auth_cognito_refresh_post() {
    let that = this;
    this.log("[auth_cognito_refresh_post] Refreshing access token...");

    return new Promise((resolve, reject) => {
      this.axios.post(`/${this.api_version}/auth/core/refresh`, {
        "refresh_token": this.getAppState().auth.refreshToken
      })
        .then( response => {
          this.log("[auth_cognito_refresh_post] Access token obtained");

          // Remember new token
          const accessToken = response.data["access_token"];
          const expiresAt = response.data["expires_at"];
          this.refreshToken(accessToken, expiresAt);

          // Complete request
          resolve(response);
        }, error => {
          this.log("[auth_cognito_refresh_post] Failed to refresh access token. Signing out...");

          // Sign out completely
          this.unAuthenticate();

          // Complete request with failure
          reject(error);
        })
        .catch(function (error) {
          that.log("[auth_cognito_refresh_post] Failed to refresh token with generic error: " + error);
        });
    });
  }

  authCognitoAnonymousGet() {
    if ( this.isAuthenticating ) {
      this.log("[authCognitoAnonymousGet] Authentication is already in progress");
      return;
    }

    this.log("[authCognitoAnonymousGet] Initial auth request observed");

    this.isAuthenticating = true;
    this.authCognitoAnonymousGetCore().then(response => {
      this.log("[authCognitoAnonymousGet] Notifying auth subscribers...");

      this.isAuthenticating = false;
      this.authSubscribers.map(callback => callback());
      this.authSubscribers = [];
    }, error => {
      this.log("[authCognitoAnonymousGet] Cleaning up subscribers upon failure to auth...");

      this.isAuthenticating = false;
      this.authSubscribers = [];
    });
  }

  authCognitoAnonymousGetCore() {
    this.log("[authCognitoAnonymousGetCore] Fetching anonymous token...");

    return new Promise((resolve, reject) => {
      this.axios.get(`/${this.api_version}/auth/core/anonymous`)
        .then( response => {
          this.log("[authCognitoAnonymousGetCore] Anonymous token acquired");

          // Remember the token
          const refreshToken = response.data["refresh_token"];
          const accessToken = response.data["access_token"];
          const expiresAt = response.data["expires_at"];
          this.authenticate(true, refreshToken, accessToken, expiresAt);

          // Complete request
          resolve(response);
        }, error => {
          this.log("[authCognitoAnonymousGetCore] Failed to fetch anonymous token");

          // Sign out completely
          this.unAuthenticate();

          // Complete request with failure
          reject(error);
        })
        .catch(function (error) {
          this.log("[authCognitoAnonymousGetCore] Failed to fetch anonymous token with generic error: " + error);
        });
    });
  }

  /**
   * users
   */

  usersIdGet(userId) {
    return new Promise((resolve, reject) => {
      const cachedUser = this.usersCacheById[userId];
      if (cachedUser) {
        resolve(cachedUser);
      }

      this.axiosRefresh.get(`/${this.api_version}/users/${userId}`)
        .then( response => {
          this.usersCacheById[userId] = response;
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  users_id_orgs_get(userId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/users/${userId}/orgs`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * orgs
   */

  orgsIdGet(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/orgs/${orgId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  orgs_id_blobs_get(orgId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/orgs/${orgId}/blobs`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Blobs
   */

  blobsIdGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsIdRevisionsGet(orgId, blobId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/revisions`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Blob Metadata
   */

  blobsIdMetadataAliasGet(orgId, blobId, alias) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/${orgId}/${blobId}/metadata/${alias}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  blobsMetadataIdGet(itemId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/blobs/metadata/${itemId}`)
         .then( response => {
           resolve(response);
         }, error => {
           reject(error);
         });
     });
   }

  /**
   * Revisions
   */

  revisionsIdGet(revisionId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/revisions/${revisionId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdDataCommandPost(revisionId, body) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/data/command`, body)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdDataQueryPost(revisionId, body) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/data/query`, body)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  /**
   * Revision Metadata
   */

  revisionsIdMetadataAliasGet(revisionId, alias) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.get(`/${this.api_version}/revisions/${revisionId}/metadata/${alias}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsIdMetadataAliasPost(revisionId, alias, item) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.post(`/${this.api_version}/revisions/${revisionId}/metadata/${alias}`, item)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsMetadataIdPut(itemId, item) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.put(`/${this.api_version}/revisions/metadata/${itemId}`, item)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  revisionsMetadataIdDelete(itemId) {
    return new Promise((resolve, reject) => {
      this.axiosRefresh.delete(`/${this.api_version}/revisions/metadata/${itemId}`)
        .then( response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }
}
