import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class Demo extends Component {
  render() {
    return (
      <>
        <Container>
          <br/>
          <Row>
            <Col>
              <strong>Graph View 1.0</strong> widget demonstration.
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <iframe
                title="graph-view-demo"
                src="/v1/graph-view/39a20322-3430-4494-b1fb-b11efaf5e3e4"
                width="70%"
                height="550px"
                frameBorder="0">
              </iframe>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
