import React, { Component } from "react";
import { Button, Alert } from "react-bootstrap";
import { GoSync } from 'react-icons/go';
import { TiWarningOutline } from 'react-icons/ti';
import Config from "context/config";
import "./index.css";

// Core components
import Graph from "./core/graph";
// Supported interpreter engines
import OrientDbSqlInterpreter from "./interpreters/orientdbSql";
// Rendering
import GraphCtrlVis from "./rendering/graphCtrlVis";
// Details
import DetailsView from "./details/index"

export default class WidgetView extends Component {
  constructor(props) {
    super(props);

    // Initialize available interpreters
    this.interpreters = {
      "orientdb_sql": new OrientDbSqlInterpreter()
    };

    this.state = {
      view: null,
      org: null,
      blob: null,
      loadingQueryIndex: 0,
      graph: new Graph(),
      graphCtrlInterface: null,
      widgetInterface: {
        updateDetails: this.updateDetails,
        updateProgress: this.updateProgress
      },
      progressEnabled: false,
      progressSuccess: null,
      progressMessage: null,
      detailsEnabled: false,
      detailsView: "summary",
      detailsData: null
    };
  }

  /**
   * Widget interface
   */

  updateDetails = (enabled, view, data) => {
    // Ignore updates if details panel is disabled
    if ( !this.state.view || !this.state.view.details || !this.state.view.details.enabled ) {
      return;
    }

    // As a policy do not show details pane if currently hidden when switching to "summary" view.
    if ( enabled && "summary" === view && !this.state.detailsEnabled ) {
      enabled = false;
    }

    // Apply new state
    this.setState({
      detailsEnabled: enabled,
      detailsView: view,
      detailsData: data
    });
  }

  updateProgress = (enabled, success, message) => {
    this.setState({
      progressEnabled: enabled,
      progressSuccess: success,
      progressMessage: message
    });
  }

  /**
   * Data loading section
   */

  loadViewSpecification() {
    this.updateProgress(true, true, "Loading graph metadata...");

    const itemId = this.props.match.params.itemId;
    this.props.app.api.blobsMetadataIdGet(itemId)
      .then(response => {
        // Remember item
        const view = response.data.item;
        this.setState({ view: view });

        // Load org/blob metadata
        this.loadBlobAndOrg(view["org_id"], view["blob_id"]);

        // Start data loading
        this.executeNextQuery();
      }, error => {
        this.updateProgress(true, false, "Failed to load metadata. Check widget configuration.");
      });
  }

  loadBlobAndOrg(orgId, blobId) {
    // Load blob
    this.props.app.api.blobsIdGet(orgId, blobId)
      .then(response => {
        this.setState({ blob: response.data.blob });
      }, error => {
        // Blob and org are non-essential for now so moving on.
      });

    // Load org
    this.props.app.api.orgsIdGet(orgId)
      .then(response => {
        this.setState({ org: response.data.org });
      }, error => {
        // Blob and org are non-essential for now so moving on.
      });
  }

  executeNextQuery() {
    if ( !this.state.view ) {
      return;
    }
    if ( this.state.loadingQueryIndex >= this.state.view.queries.length ) {
      this.updateProgress(false, null, null);
      return;
    }

    // Update progress
    this.updateProgress(true, true,
      `Running query ${this.state.loadingQueryIndex + 1} of ${this.state.view.queries.length}...`);

    // Grab next query
    const query = this.state.view.queries[this.state.loadingQueryIndex];
    const revisionId = query["revision_id"];
    const value = query["value"];
    const engine = query["interpreter"]["engine"];

    // Prepare request
    const interpreter = this.interpreters[engine];
    const command = interpreter.prepareCommandBody(value);

    // Fetch data
    this.props.app.api.revisionsIdDataQueryPost(revisionId, command)
      .then(response => {
        // Convert response to internal graph representation.
        const graph = interpreter.successResponseToGraph(response.data);

        // Add result to the preview graph.
        this.state.graph.incorporate(graph);

        // Notify renderer
        this.state.graphCtrlInterface.incorporateDiff(this.state.loadingQueryIndex);

        // Proceed to the next query
        this.proceedToTheNextQuery();
      }, error => {
        // NEXT: Retry of indicate failure
        // For now just proceed to the next query
        this.proceedToTheNextQuery();
      });
  }

  proceedToTheNextQuery(response) {
    // Perform delayed transition
    let postDelay = 1;
    const query = this.state.view.queries[this.state.loadingQueryIndex];
    if ( query.appearance && query.appearance.flow && query.appearance.flow.post ) {
      postDelay = query.appearance.flow.post.delay || 1;
    }

    // Transition to the next query
    this.setState({
      loadingQueryIndex: this.state.loadingQueryIndex + 1
    });

    // Start delayed execution
    setTimeout(() => {
      this.executeNextQuery();
    }, postDelay);
  }

  /**
   * Widget UX
   */

  componentDidMount() {
    // Initiate loading
    this.loadViewSpecification();
  }

  getBlobUrl() {
    let url = Config.webBaseUrl();
    if ( this.state.org && this.state.blob ) {
      url += `/${this.state.org.alias}/${this.state.blob.alias}`;
    }
    return url;
  }

  render() {
    return (
      <div className="widget-container">
        {this.state.progressEnabled &&
          <Alert variant={this.state.progressSuccess ? "secondary" : "warning"} className="loading-progress">
            {this.state.progressSuccess ? <GoSync/> : <TiWarningOutline/>}
            {this.state.progressMessage}
          </Alert>
        }

        {this.state.detailsEnabled &&
          <DetailsView
            widget={this.state} />
        }

        <GraphCtrlVis
          widget={this.state}
          view={this.state.view}
          graph={this.state.graph} />

        <Button
          href={this.getBlobUrl()}
          target="__blank"
          className="blobhub-main-link"
          variant="secondary">
          <img src="/images/icon_white.png" alt="BlobHub logo"/> by BlobHub
        </Button>
      </div>
    );
  }
}
