import React, { Component } from 'react';
import merge from "lodash/merge";
import './index.css';

import SummaryView from './summary';
import VertexView from './vertex';
import EdgeView from './edge';

export default class DetailsPane extends Component {
  constructor(props) {
    super(props);
    this.prepareCardStyle();
  }

  static positionStyles = {
    "bottom_left": {
      "bottom": "8px",
      "left": "10px"
    },
    "top_right": {
      "top": "8px",
      "right": "10px"
    }
  };

  static defaultPosition = "bottom_left";

  prepareCardStyle() {
    // Default styling
    let cardStyle = {};
    let position = DetailsPane.defaultPosition;
    let positionStyle = DetailsPane.positionStyles[position];

    // Override with view configuration
    if ( this.props.widget.view && this.props.widget.view.details && this.props.widget.view.details.appearance ) {
      cardStyle = this.props.widget.view.details.appearance.style || {};
      position = this.props.widget.view.details.appearance.position || position;
      positionStyle = DetailsPane.positionStyles[position];

      // Revert to default if specified position is not supported
      if ( !positionStyle ) {
        position = DetailsPane.defaultPosition;
        positionStyle = DetailsPane.positionStyles[position];
      }
    }

    // Remember combined style
    this.cardStyle = merge(cardStyle, positionStyle);
  }

  render() {
    return (
      <>
        {"summary" === this.props.widget.detailsView &&
          <SummaryView
            widget={this.props.widget}
            cardStyle={this.cardStyle}
          />
        }
        {"vertex" === this.props.widget.detailsView &&
          <VertexView
            widget={this.props.widget}
            cardStyle={this.cardStyle}
          />
        }
        {"edge" === this.props.widget.detailsView &&
          <EdgeView
            widget={this.props.widget}
            cardStyle={this.cardStyle}
          />
        }
      </>
    );
  }
}
