import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import CloseControl from './close';

export default class View extends Component {
  render() {
    return (
      <Card style={this.props.cardStyle}>
        <Card.Header>
          Graph Details
          <span className="float-right">
            <CloseControl widget={this.props.widget} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <table className="properties">
            <tbody>
              <tr>
                <td className="prop-name-column">Domain/Type</td>
                <td className="prop-value-column">graph/generic</td>
              </tr>
              {this.props.widget.org &&
                <tr>
                  <td className="prop-name-column">Organization</td>
                  <td className="prop-value-column">{this.props.widget.org.alias}</td>
                </tr>
              }
              {this.props.widget.blob &&
                <tr>
                  <td className="prop-name-column">Blob</td>
                  <td className="prop-value-column">{this.props.widget.blob.alias}</td>
                </tr>
              }
            </tbody>
          </table>
        </Card.Body>
      </Card>
    );
  }
}
